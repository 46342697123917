import React, {ReactNode, useState} from 'react';
import Link from 'next/link';
import icons, {IconNames} from '../../icons';
//Components
import Spinner from '../Spinner';
// Style
import S, {colors} from './style';

export type Sizes = 'small' | 'medium' | 'large';
export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'lightning'
  | 'lightning secondary'
  | 'primary inverse'
  | 'secondary inverse'
  | 'tertiary inverse'
  | 'negative';
interface IconProps {
  size?: string | number;
  contentColor?: string;
}

export interface ButtonProps {
  text: string | ReactNode;
  type: ButtonTypes;
  disabled?: boolean;
  onClick?: Function;
  width?: string;
  loading?: boolean;
  icon?: IconNames;
  size: Sizes;
  href?: string;
  tabIndex?: number;
  justifyContent?: string;
}

const Button = ({
  text,
  type,
  disabled = false,
  onClick,
  width,
  loading = false,
  icon,
  size,
  href,
  justifyContent = 'center',
}: ButtonProps) => {
  const [focused, setFocused] = useState(false);

  const handleClick = e => {
    e.stopPropagation();
    if (!onClick) {
      return null;
    }
    return onClick(e);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !disabled && !loading && onClick) {
      return onClick(e);
    }
  };

  const getSpinnerType = () => {
    if (
      type === 'primary inverse' ||
      type === 'secondary inverse' ||
      type === 'tertiary inverse' ||
      type === 'lightning secondary'
    ) {
      return 'inverse';
    } else {
      return 'default';
    }
  };

  const getColor = () => {
    if (disabled) {
      return colors.text.disabled[type];
    }
    return colors.text.default[type];
  };

  const getContent = () => {
    const Icon: React.FC<IconProps> | null = icon ? icons[icon] : null;
    return (
      <S.ButtonWrapper
        $width={width}
        $type={type}
        disabled={disabled || loading}
      >
        {focused && <S.FocusBorder $type={type} $size={size} />}
        <S.Button
          disabled={disabled || loading}
          $isLoading={loading}
          $size={size}
          $type={type}
          $width={width}
          $justifyContent={justifyContent}
        >
          <S.TextWrapper>
            {Icon && (
              <S.Icon>
                <Icon contentColor={getColor()} size={24} />
              </S.Icon>
            )}
            <S.Text
              $type={type}
              disabled={disabled || loading}
              $isLoading={loading}
              $size={size}
            >
              {text}
            </S.Text>
            {loading && (
              <S.LoaderWrapper>
                <Spinner size="small" type={getSpinnerType()} />
              </S.LoaderWrapper>
            )}
          </S.TextWrapper>
        </S.Button>
      </S.ButtonWrapper>
    );
  };

  // div with focus properties has to come immediately after <Link />
  if (href && !disabled) {
    return (
      <Link href={href} passHref>
        <S.ButtonWrapperOuter
          $width={width}
          as="a"
          tabIndex={0}
          disabled={false}
          aria-disabled={false}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
        >
          {getContent()}
        </S.ButtonWrapperOuter>
      </Link>
    );
  }

  return (
    <S.ButtonWrapperOuter
      $width={width}
      tabIndex={disabled ? -1 : 0}
      onKeyDown={handleKeyDown}
      onClick={disabled || loading ? null : handleClick}
      disabled={disabled || loading}
      onFocus={() => {
        if (!disabled && !loading) setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
    >
      {getContent()}
    </S.ButtonWrapperOuter>
  );
};

export default React.memo(Button);
